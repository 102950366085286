import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getProducts } from "../api";
import arrowIcon from "../img/arrow-left.svg";
import { useSearchParams } from "react-router-dom";

import sortIcon from "../img/sort.svg";
import filterIcon from "../img/filter.svg";
import searchIcon from "../img/search_gray.svg";

function formatNumberWithSpaces(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const Search = () => {
  const nav = useNavigate();
  const [query, setQuery] = useState("");
  const [cart, setCart] = useState([]);
  const [products, setProducts] = useState([]);
  const [results, setResults] = useState([]);
  const [filterModal, setFilterModal] = useState(false);
  const [sortModal, setSortModal] = useState(false);
  const [priceFrom, setPriceFrom] = useState("");
  const [priceTo, setPriceTo] = useState("");
  const [article, setArticle] = useState("");
  const [status, setStatus] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [pendingFilters, setPendingFilters] = useState({});
  const [pendingSortOrder, setPendingSortOrder] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const [priceAccordionOpen, setPriceAccordionOpen] = useState(true);
  const [statusAccordionOpen, setStatusAccordionOpen] = useState(true);
  const [articleAccordionOpen, setArticleAccordionOpen] = useState(true);

  const togglePriceAccordion = () => {
    setPriceAccordionOpen(!priceAccordionOpen);
  };

  const toggleStatusAccordion = () => {
    setStatusAccordionOpen(!statusAccordionOpen);
  };

  const toggleArticleAccordion = () => {
    setArticleAccordionOpen(!articleAccordionOpen);
  };

  const toggleFilter = () => {
    setFilterModal(!filterModal);
  };

  const toggleSort = () => {
    setSortModal(!sortModal);
  };

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      setCart(JSON.parse(localStorage.getItem("cart")));
    }
    async function fetchData() {
      const _products = await getProducts();
      const __products = [];

      for (let i in _products) {
        if (_products[i].categoryName !== "Новинки") {
          for (let j in _products[i].products) {
            __products.push(_products[i].products[j]);
          }
        }
      }

      setProducts(__products);

      if (searchParams.get("q")) {
        setQuery(searchParams.get("q"));
      }
    }

    fetchData();
  }, []);

  const applyFilters = () => {
    setQuery(pendingFilters.query || "");
    setPriceFrom(pendingFilters.priceFrom || "");
    setPriceTo(pendingFilters.priceTo || "");
    setArticle(pendingFilters.article || "");
    setStatus(pendingFilters.status || "");
    setFilterModal(false);
  };

  const applySort = () => {
    setSortOrder(pendingSortOrder);
    setSortModal(false);
  };

  useEffect(() => {
    const res = products.filter((item) => {
      const matchesQuery =
        item.article.toLowerCase().includes(query.toLowerCase()) ||
        item.description.toLowerCase().includes(query.toLowerCase());
      const matchesPrice =
        (!priceFrom || parseFloat(item.price) >= parseFloat(priceFrom)) &&
        (!priceTo || parseFloat(item.price) <= parseFloat(priceTo));
      const matchesArticle = item.article
        .toLowerCase()
        .includes(article.toLowerCase());
      const matchesStatus =
        (status === "inStock" && item.inStock > 0) ||
        (status === "outOfStock" && item.inStock === 0) ||
        status === "all" ||
        status === "";

      return matchesQuery && matchesPrice && matchesArticle && matchesStatus;
    });

    if (sortOrder === "priceAsc") {
      res.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    } else if (sortOrder === "priceDesc") {
      res.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    } else if (sortOrder === "newest") {
      res.sort((a, b) => parseInt(b.id) - parseInt(a.id));
    } else if (sortOrder === "oldest") {
      res.sort((a, b) => parseInt(a.id) - parseInt(b.id));
    } else if (sortOrder === "bestsellers") {
      res.sort((a, b) => parseInt(a.inBox) - parseInt(b.inBox));
    }

    setResults(res);
  }, [query, priceFrom, priceTo, article, status, sortOrder, products]);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  return (
    <>
      {filterModal && (
        <div className="modal-container">
          <div className="modal-overlay"></div>
          <div className="modal-content">
            <form>
              <div>
                <div>
                  <button className="close-button" onClick={toggleFilter}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        fill="currentColor"
                        d="M18 6.41L16.59 5 12 9.59 7.41 5 6 6.41 10.59 11 6 15.59 7.41 17 12 12.41 16.59 17 18 15.59 13.41 11z"
                      />
                    </svg>
                  </button>
                  <h3>Фильтр</h3>
                </div>

                <div className="accordion">
                  <div
                    className="accordion-header"
                    onClick={toggleStatusAccordion}
                  >
                    <label>Статус продажи</label>
                    <svg
                      className={`arrow ${priceAccordionOpen ? "open" : ""}`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        fill="currentColor"
                        d="M12 15.414l6.293-6.293 1.414 1.414-7.707 7.707-7.707-7.707 1.414-1.414z"
                      />
                    </svg>
                  </div>
                  {statusAccordionOpen && (
                    <div className="accordion-content">
                      <div className="form-group-radio">
                        <input
                          type="radio"
                          value="all"
                          id="all"
                          checked={pendingFilters.status === "all"}
                          onChange={() =>
                            setPendingFilters({
                              ...pendingFilters,
                              status: "all",
                            })
                          }
                        />
                        <label htmlFor="all">Все товары</label>
                        <input
                          type="radio"
                          value="inStock"
                          id="inStock"
                          checked={pendingFilters.status === "inStock"}
                          onChange={() =>
                            setPendingFilters({
                              ...pendingFilters,
                              status: "inStock",
                            })
                          }
                        />
                        <label htmlFor="inStock">Есть в наличии</label>
                        <input
                          type="radio"
                          value="outOfStock"
                          id="outOfStock"
                          checked={pendingFilters.status === "outOfStock"}
                          onChange={() =>
                            setPendingFilters({
                              ...pendingFilters,
                              status: "outOfStock",
                            })
                          }
                        />
                        <label htmlFor="outOfStock">Нет в наличии</label>
                      </div>
                    </div>
                  )}
                </div>
                <div className="accordion">
                  <div
                    className="accordion-header"
                    onClick={togglePriceAccordion}
                  >
                    <label>Цена</label>
                    <svg
                      className={`arrow ${priceAccordionOpen ? "open" : ""}`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        fill="currentColor"
                        d="M12 15.414l6.293-6.293 1.414 1.414-7.707 7.707-7.707-7.707 1.414-1.414z"
                      />
                    </svg>
                  </div>
                  {priceAccordionOpen && (
                    <div className="accordion-content">
                      <div className="form-group">
                        <input
                          type="number"
                          className="formInput"
                          placeholder="От"
                          value={pendingFilters.priceFrom || ""}
                          onChange={(e) =>
                            setPendingFilters({
                              ...pendingFilters,
                              priceFrom: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="number"
                          className="formInput"
                          placeholder="До"
                          value={pendingFilters.priceTo || ""}
                          onChange={(e) =>
                            setPendingFilters({
                              ...pendingFilters,
                              priceTo: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="accordion">
                  <div
                    className="accordion-header"
                    onClick={toggleArticleAccordion}
                  >
                    <label>Артикул</label>
                    <svg
                      className={`arrow ${priceAccordionOpen ? "open" : ""}`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        fill="currentColor"
                        d="M12 15.414l6.293-6.293 1.414 1.414-7.707 7.707-7.707-7.707 1.414-1.414z"
                      />
                    </svg>
                  </div>
                  {articleAccordionOpen && (
                    <div className="accordion-content">
                      <div className="form-group">
                        <div className="search-article">
                          <img src={searchIcon} alt="" />
                          <input
                            type="text"
                            className="formInput"
                            placeholder="Поиск по значениям"
                            value={pendingFilters.article || ""}
                            onChange={(e) =>
                              setPendingFilters({
                                ...pendingFilters,
                                article: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <button
                type="button"
                className="formButton"
                onClick={applyFilters}
              >
                Применить
              </button>
            </form>
          </div>
        </div>
      )}
      {sortModal && (
        <div className="modal-container">
          <div className="modal-overlay"></div>
          <div className="modal-content">
            <form>
              <div>
                <div>
                  <button className="close-button" onClick={toggleSort}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        fill="currentColor"
                        d="M18 6.41L16.59 5 12 9.59 7.41 5 6 6.41 10.59 11 6 15.59 7.41 17 12 12.41 16.59 17 18 15.59 13.41 11z"
                      />
                    </svg>
                  </button>
                  <h3>Сортировка</h3>
                </div>
                <div className="form-group-radio">
                  <input
                    type="radio"
                    value="priceAsc"
                    name="sortOrder"
                    id="priceAsc"
                    checked={pendingSortOrder === "priceAsc"}
                    onChange={() => setPendingSortOrder("priceAsc")}
                  />
                  <label htmlFor="priceAsc">Цене (по возрастанию)</label>
                  <input
                    type="radio"
                    value="priceDesc"
                    name="sortOrder"
                    id="priceDesc"
                    checked={pendingSortOrder === "priceDesc"}
                    onChange={() => setPendingSortOrder("priceDesc")}
                  />
                  <label htmlFor="priceDesc">Цене (по убыванию)</label>
                  <input
                    type="radio"
                    value="bestsellers"
                    name="sortOrder"
                    id="bestsellers"
                    checked={pendingSortOrder === "bestsellers"}
                    onChange={() => setPendingSortOrder("bestsellers")}
                  />
                  <label htmlFor="bestsellers">Бестселлеры</label>
                  <input
                    type="radio"
                    value="newest"
                    name="sortOrder"
                    id="newest"
                    checked={pendingSortOrder === "newest"}
                    onChange={() => setPendingSortOrder("newest")}
                  />
                  <label htmlFor="newest">Недавно добавленные</label>
                  <input
                    type="radio"
                    value="oldest"
                    name="sortOrder"
                    id="oldest"
                    checked={pendingSortOrder === "oldest"}
                    onChange={() => setPendingSortOrder("oldest")}
                  />
                  <label htmlFor="oldest">Давно добавленные</label>
                </div>
              </div>
              <button type="button" className="formButton" onClick={applySort}>
                Применить
              </button>
            </form>
          </div>
        </div>
      )}
      <div className="container-order-data">
        <div className="order-form">
          <p className="order-data-page-title">
            <img
              src={arrowIcon}
              onClick={() => {
                nav("/");
              }}
            />
            Поиск
          </p>
          <div className="form-search">
            <div className="form-group-section">
              <div className="form-group">
                <input
                  type="text"
                  className="formInput"
                  placeholder="Поиск..."
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="search-modals">
              <div className="form-filter">
                <button onClick={toggleFilter}>
                  <img src={filterIcon} alt="filter icon" />
                  <span>Фильтры</span>
                </button>
              </div>
              <div className="form-sort">
                <button onClick={toggleSort}>
                  <img src={sortIcon} alt="sort icon" />
                  <span>Сортировка</span>
                </button>
              </div>
            </div>
          </div>
          <br />
          <div className="menuContainer2">
            {results.map((product) => {
              const inCart = cart.find(
                (_product) => _product.id === product.id
              );
              if (!product.price || parseInt(product.price) === 0) return null;
              if (!product.inStock || parseInt(product.inStock) === 0)
                return null;
              return (
                <div className="menuItem" key={product.id}>
                  <div className="info">
                    <img
                      onClick={() => {
                        localStorage.setItem(
                          "product",
                          JSON.stringify({
                            ...product,
                            inCart: inCart ? inCart.quantity : 0,
                          })
                        );
                        nav(`/product/${product.id}`);
                      }}
                      src={`https://shop-api.toyseller.site/api/image/${product.id}/${product.image}`}
                      alt="food"
                      className="picture"
                    />
                    <div className="name">{product.article}</div>
                    <div className="weight">
                      PM3: <br />
                      {product.inBox} шт
                      <br />
                    </div>
                  </div>
                  {inCart ? (
                    <div className="add catalog_counter">
                      <div
                        className="cic-minus"
                        onClick={() => {
                          let minusAmount = 1;
                          if (
                            parseInt(product.inBox) >=
                            parseInt(inCart.quantity * product.inBox)
                          ) {
                            minusAmount =
                              1 /
                              (parseInt(product.inBox) /
                                parseInt(product.inPackage));
                          } else if (
                            parseInt(product.inBox) +
                              parseInt(product.inTheBox) <=
                            parseInt(inCart.quantity * product.inBox)
                          ) {
                            minusAmount =
                              parseInt(product.inTheBox) /
                              parseInt(product.inBox);
                          }

                          if (
                            parseFloat(
                              (inCart.quantity - minusAmount).toFixed(2)
                            ) > 0
                          ) {
                            setCart(
                              cart.map((_product) => {
                                if (_product.id === product.id) {
                                  return {
                                    ..._product,
                                    quantity: parseFloat(
                                      (_product.quantity - minusAmount).toFixed(
                                        2
                                      )
                                    ),
                                  };
                                }
                                return _product;
                              })
                            );
                          } else {
                            setCart(
                              cart.filter(
                                (_product) => _product.id !== product.id
                              )
                            );
                          }
                        }}
                      >
                        <svg
                          className="svg"
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0"
                          y="0"
                          viewBox="0 0 341.333 341.333"
                          style={{ enableBackground: "new 0 0 512 512" }}
                          xmlSpace="preserve"
                        >
                          <rect
                            y="149.333"
                            width="341.333"
                            height="42.667"
                            fill="#f6f6f6"
                          ></rect>
                        </svg>
                      </div>
                      <div className="amount">
                        {parseInt(inCart.quantity * product.inBox)}
                      </div>
                      <div
                        className="cic-plus"
                        onClick={() => {
                          if (
                            inCart.quantity <
                            product.inStock *
                              (parseInt(product.inTheBox) /
                                parseInt(product.inBox))
                          ) {
                            let incrementAmount = 1;
                            if (
                              parseInt(product.inBox) >
                              parseInt(inCart.quantity * product.inBox)
                            ) {
                              incrementAmount =
                                1 /
                                (parseInt(product.inBox) /
                                  parseInt(product.inPackage));
                            }
                            setCart(
                              cart.map((_product) => {
                                if (_product.id === product.id) {
                                  return {
                                    ..._product,
                                    quantity: parseFloat(
                                      (
                                        _product.quantity + incrementAmount
                                      ).toFixed(2)
                                    ),
                                  };
                                }
                                return _product;
                              })
                            );
                          }
                        }}
                      >
                        <svg
                          className="svg"
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0"
                          y="0"
                          viewBox="0 0 341.4 341.4"
                          style={{ enableBackground: "new 0 0 512 512" }}
                          xmlSpace="preserve"
                        >
                          <polygon
                            points="192,149.4 192,0 149.4,0 149.4,149.4 0,149.4 0,192 149.4,192 149.4,341.4 192,341.4 192,192 341.4,192 341.4,149.4"
                            fill="#f6f6f6"
                            className=""
                          ></polygon>
                        </svg>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="price"
                      onClick={() => {
                        localStorage.setItem(
                          "product",
                          JSON.stringify({
                            ...product,
                          })
                        );
                        nav("/product/" + product.id);
                      }}
                    >
                      {formatNumberWithSpaces(Number(product.price))} ₽
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Search;
