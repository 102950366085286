import { useEffect } from "react";
import { useNavigate } from "react-router";
import logo from "../img/logo.png";
import { LoginButton } from "@telegram-auth/react";

const AuthTelegram = () => {
  const nav = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("user")) {
      nav("/");
      return;
    }
  }, []);
  const login = async () => {
    //   localStorage.setItem("login", data.login);
    //   localStorage.setItem("password", data.password);
    // const user = await getUser();
    // if (user) {
    //   nav("/");
    // } else {
    //   localStorage.removeItem("login");
    //   localStorage.removeItem("password");
    //   window.location.reload();
    // }
  };

  return (
    <>
      <div class="container-order-data">
        <div class="order-form order-form-auth">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={logo} width={200} />
          </div>

          <main className="telegram-wrapper">
            <LoginButton
              botUsername="test_toyseller_bot"
              // authCallbackUrl="/"
              buttonSize="large" // "large" | "medium" | "small"
              cornerRadius={5} // 0 - 20
              showAvatar={false}
              lang="ru"
              onAuthCallback={(data) => {
                console.log(data);
                localStorage.setItem("user", JSON.stringify(data));
                nav("/");
              }}
            />
          </main>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                color: "rgba(255, 255, 255, 0.5)",
              }}
            >
              Мы выбрали{" "}
              <a href="https://spruton.shop/" target="_blank" rel="noreferrer">
                СПРУТОН МАРКЕТ
              </a>{" "}
              для создания магазина
            </p>
            <p>
              Техподдержка:{" "}
              <a href="mailto:support@spruton.shop">support@spruton.shop</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthTelegram;
