import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import arrowIcon from "../img/arrow-left.svg";

function formatNumberWithSpaces(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const Cart = () => {
  const nav = useNavigate();
  const [cart, setCart] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      setCart(JSON.parse(localStorage.getItem("cart")));
    }

    return () => {};
  }, []);

  const goBack = () => {
    nav("/");
  };

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));

    let price = 0;
    cart.forEach((product) => {
      price += product.price * product.quantity * product.inBox;
    });
    setTotalPrice(parseInt(price));
  }, [cart]);

  const goToOrder = () => {
    nav("/order");
  };

  const deleteCart = async () => {
    await localStorage.setItem("cart", JSON.stringify([]));
    setCart([]);
    nav("/");
  };

  return (
    <>
      <div class="container-order">
        <div class="page-title-order">
          <img
            src={arrowIcon}
            onClick={() => {
              nav("/");
            }}
          />
          <span>Заказ</span>
          <button class="cart-empty" onClick={() => deleteCart()}></button>
        </div>
        <div class="cart1">
          {cart.map((product) => (
            <div
              class="cart-item-row"
              style={{ position: "relative", margin: "5px 0" }}
            >
              <img
                src={`/close.svg`}
                alt="picture"
                className="close_ic"
                onClick={() => {
                  setCart(cart.filter((i) => i.id !== product.id));
                }}
              />
              <div
                class="cart-item-picture"
                onClick={() => {
                  if (parseInt(product.inStock <= 0)) return;
                  localStorage.setItem(
                    "product",
                    JSON.stringify({
                      ...product,
                    })
                  );
                  nav("/product/" + product.id);
                }}
              >
                <img
                  src={`https://shop-api.toyseller.site/api/image/${product.id}/${product.image}`}
                  alt="picture"
                />
              </div>
              <div
                class="cart-item-data"
                onClick={() => {
                  if (parseInt(product.inStock <= 0)) return;
                  localStorage.setItem(
                    "product",
                    JSON.stringify({
                      ...product,
                    })
                  );
                  nav("/product/" + product.id);
                }}
              >
                <div class="cart-item-label">
                  {product.article}
                  <div class="cart-item-caption">
                    PM3: {product.inBox} шт
                    <br />
                  </div>
                  <span>
                    {formatNumberWithSpaces(
                      parseInt(product.quantity * product.inBox) %
                        product.inPackage !==
                        0
                        ? Math.ceil(product.quantity * product.inBox) *
                            product.price
                        : parseInt(product.quantity * product.inBox) *
                            product.price
                    )}{" "}
                    ₽
                  </span>
                </div>
                {Number(product.inStock) > 0 ? (
                  <>
                    <div
                      class="cart-item-counter"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div
                        class="cic-minus"
                        onClick={() => {
                          let minusAmount = 1;
                          if (
                            parseInt(product.inBox) >=
                            parseInt(product.quantity * product.inBox)
                          ) {
                            minusAmount =
                              1 /
                              (parseInt(product.inBox) /
                                parseInt(product.inPackage));
                          } else if (
                            parseInt(product.inBox) +
                              parseInt(product.inTheBox) <=
                            parseInt(product.quantity * product.inBox)
                          ) {
                            minusAmount =
                              parseInt(product.inTheBox) /
                              parseInt(product.inBox);
                          }

                          const newQuantity =
                            product.quantity.toFixed(3) > minusAmount.toFixed(3)
                              ? parseFloat(product.quantity - minusAmount)
                              : 0;

                          if (newQuantity > 0) {
                            setCart(
                              cart.map((_product) => {
                                if (_product.id === product.id) {
                                  return {
                                    ..._product,
                                    quantity: newQuantity,
                                  };
                                }
                                return _product;
                              })
                            );
                          } else {
                            setCart(
                              cart.filter(
                                (_product) => _product.id != product.id
                              )
                            );
                          }
                        }}
                      >
                        <svg
                          class="svg"
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0"
                          y="0"
                          viewBox="0 0 341.333 341.333"
                          style={{
                            "enable-background": "new 0 0 512 512",
                          }}
                          xmlSpace="preserve"
                        >
                          <rect
                            y="149.333"
                            width="341.333"
                            height="42.667"
                            fill="#f6f6f6"
                          ></rect>
                        </svg>
                      </div>
                      <div class="cic-count">
                        {parseInt(product.quantity * product.inBox) %
                          product.inPackage !==
                        0
                          ? Math.ceil(product.quantity * product.inBox)
                          : parseInt(product.quantity * product.inBox)}
                      </div>
                      <div
                        class="cic-plus"
                        onClick={() => {
                          if (
                            product.quantity <
                            product.inStock *
                              (parseInt(product.inTheBox) /
                                parseInt(product.inBox))
                          ) {
                            let incrementAmount = 1;
                            if (
                              parseInt(product.inBox) >
                              parseInt(product.quantity * product.inBox)
                            ) {
                              incrementAmount =
                                1 /
                                (parseInt(product.inBox) /
                                  parseInt(product.inPackage)); //parseInt(product.inPackage)
                            }
                            setCart(
                              cart.map((_product) => {
                                if (_product.id === product.id) {
                                  return {
                                    ..._product,
                                    quantity: parseFloat(
                                      (
                                        _product.quantity + incrementAmount
                                      ).toFixed(2)
                                    ),
                                  };
                                }
                                return _product;
                              })
                            );
                          }
                        }}
                      >
                        <svg
                          class="svg"
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0"
                          y="0"
                          viewBox="0 0 341.4 341.4"
                          style={{
                            "enable-background": "new 0 0 512 512",
                          }}
                          xmlSpace="preserve"
                        >
                          <polygon
                            points="192,149.4 192,0 149.4,0 149.4,149.4 0,149.4 0,192 149.4,192 149.4,341.4 192,341.4 192,192 341.4,192 341.4,149.4"
                            fill="#f6f6f6"
                            class=""
                          ></polygon>
                        </svg>
                      </div>
                    </div>
                  </>
                ) : (
                  <div class="cart-item-counter notqqq">
                    <div>Нет в наличии</div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              color: "rgba(255, 255, 255, 0.5)",
            }}
          >
            Работает на базе Spruton
          </p>
        </div>
        {totalPrice == 0 ? (
          <center>
            <p>Ваша корзина пуста</p>
          </center>
        ) : (
          <></>
        )}
        {totalPrice > 0 && (
          <>
            <div class="go-to-order-wrap">
              Заказ на {formatNumberWithSpaces(totalPrice)} ₽
            </div>
            <div class="mainButton" onClick={goToOrder}>
              Верно, далее
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Cart;
