import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import arrowIcon from "../img/arrow-left.svg";
import arrow2Icon from "../img/arrow-right.svg";

function formatNumberWithSpaces(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const OrderInfo = () => {
  const nav = useNavigate();
  const { id } = useParams();

  const [user, setUser] = useState({
    orders: [],
  });
  const [order, setOrder] = useState({
    products: [],
  });

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const _user = JSON.parse(localStorage.getItem("user"));
      setUser(_user);
    }
    if (localStorage.getItem("order")) {
      const _order = JSON.parse(localStorage.getItem("order"));
      setOrder(_order);
    }
  }, []);

  return (
    <>
      <div class="container-order">
        <div
          class="page-title-order"
          style={{
            justifyContent: "left",
          }}
        >
          <img
            src={arrowIcon}
            style={{
              marginRight: 12,
            }}
            onClick={() => {
              nav("/orders");
            }}
          />
          <span>Заказ №{id}</span>
        </div>

        <p>Дата заказа: {new Date(order.orderDate * 1000).toLocaleString()}</p>

        <p>Кол-во товаров: {order.products.length}</p>
        <div class="cart">
          {order.products.map((product) => (
            <div
              class="cart-item-row"
              onClick={() => {
                nav(`/product/${product.id}`);
              }}
            >
              <div class="cart-item-picture">
                <img
                  src={`https://shop-api.toyseller.site/api/image/${product.productId}/${product.image}`}
                  alt="picture"
                />
              </div>
              <div class="cart-item-data">
                <div class="cart-item-label">
                  {product.article}
                  <div class="cart-item-caption">
                    PM3: {product.inBox}
                    <br />
                  </div>
                  <span>
                    {formatNumberWithSpaces(
                      parseInt(product.quantity * product.inBox) %
                        product.inPackage !==
                        0
                        ? Math.ceil(product.quantity * product.inBox) *
                            product.price
                        : parseInt(product.quantity * product.inBox) *
                            product.price
                    )}{" "}
                    ₽
                  </span>
                </div>
                <div class="cart-item-counter">
                  <div
                    class="cic-count"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    {
                      //tt
                      parseInt(product.quantity * product.inBox) %
                        product.inPackage !==
                      0
                        ? Math.ceil(product.quantity * product.inBox)
                        : parseInt(product.quantity * product.inBox)
                    }{" "}
                    шт.
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default OrderInfo;
